html,
body {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 5px;
  background-color: white;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e44041;
  border-radius: 10px;
}
.App {
  background-image: url("./assets/mainBack.jpg");
  min-height: 100vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.font-rale {
  font-family: "Raleway", sans-serif;
}

.wid-85 {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.clr-white {
  color: white;
}
.clr-blue {
  color: #283059;
}
.bold-8 {
  font-weight: 800;
}
.bold-5 {
  font-weight: 500;
}
.bold-3 {
  font-weight: 300;
}
.f-s-3 {
  font-size: 2.5vw;
}
.f-s-2 {
  font-size: 2vw;
}
.f-s-1-5 {
  font-size: 1.5vw;
}
.font-b {
  font-weight: bolder;
}
.margin-0 {
  margin: 0;
}
.head {
  background-image: url("./assets/headBack.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.navbar {
  padding-top: 3vh;
  padding-bottom: 13vh;
  max-height: 20vh;
  overflow: hidden;
}
.nav-logo {
  width: 3vw;
  height: 3vw;
}
.contact-us {
  display: inline-block;
  float: right;
  text-decoration: none;
  position: relative;
  top: 1vh;
  font-family: "Raleway", sans-serif;
}
.head-data-cont {
  display: flex;
  flex-direction: row;
}
.head-data {
  flex: 1.6;
}
.ar-cont {
  flex: 3;
  height: 83vh;
  position: relative;
  bottom: 5vh;
}

.head-main-heading {
  font-size: 4.5vw;
  margin-bottom: 0;
  margin-top: 4vh;
  font-family: "Raleway", sans-serif;
}
.head-main-line {
  font-size: 2.5vw;
}

.bike-qr-container {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5vw;
  border-radius: 10px;
}
.bike-qr {
  flex: 1;
  width: 10px;
  border-radius: 10px;
}
.bike-qr-text-container {
  flex: 3;
  margin-left: 1vw;
}
.mobile-qr {
  display: none;
}
.bike-iframe {
  border: none;
  position: relative;
  bottom: 10%;
  width: 100%;
  height: 100%;
  z-index: +10;
}
.iframe-overlay {
  border: none;
  position: relative;
  bottom: 105%;
  width: 100%;
  height: 100%;
  z-index: +20;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 2vw;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  font-family: "Raleway", sans-serif;
}
.iframe-overlay > p {
  padding: 0.5vw 1vw;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50px;
}

.project-head {
  text-align: center;
  margin-top: 9vh;
  /* margin-bottom: 8vh; */
  font-size: 3vw;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
}
.project-cont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.indi-pro-cont {
  margin-left: 2vw;
  margin-right: 2vw;
}
.indi-pro-img {
  display: block;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  transform: scale(1);
  transition: 1s;
  border-radius: 30px;
}
.indi-pro-img:hover {
  transform: scale(1.1);
}
.ini-pro-data {
  display: flex;
  flex-direction: row;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  align-items: center;
  bottom: 13vh;
}
.ini-pro-data > p {
  /* width: 10px; */
  flex: 4;
  color: white;
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  font-size: 2vw;
}
.ini-pro-data > img {
  width: 10%;
  flex: 1;
  border-radius: 10px;
}
.form {
  min-height: 120vh;
  align-items: center;
  display: flex;
  flex-direction: row;
}
.form-tagline-cont {
  flex: 1;
}
.form-tagline-cont > p {
  font-size: 3vw;
  font-weight: 500;
  color: white;
  width: 80%;
  margin-left: auto;
  letter-spacing: 0.2vw;
  /* margin-right: auto; */
  line-height: 131%;
  /* text-shadow: -6px 2px 5px #E44041; */
  font-family: "Raleway", sans-serif;
}
.form > form {
  flex: 1;
}
.form-data-cont {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  /* min-height: 45vh; */
  border-radius: 30px;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
  padding-top: 5vh;
  padding-bottom: 5vh;
}
.form-feilds-head {
  margin: 0;
  width: fit-content;
  margin-left: 12%;
  /* margin-right: auto; */
  position: relative;
  top: 1vh;
  background-color: white;
}

.input-feild {
  padding: 1vw;
  border-radius: 10px;
  border: 1px solid #a6a6a6;
  margin-bottom: 1vh;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.textarea-feild {
  display: block;
  width: 80%;
  height: 10vh;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  border-bottom: 1px solid #a6a6a6;
  padding: 1vw;
  font-family: Arial, Helvetica, sans-serif;
  resize: none;
}
.form-sub-butt {
  padding: 1vw;
  border-radius: 50px;
  color: white;
  background-color: #e44041;
  border: none;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4vh;
}
.respond-message {
  color: #a6a6a6;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-style: italic;
  font-family: "Raleway", sans-serif;
}
.advantage {
  min-height: 60vh;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.advantage > p {
  text-align: center;
  margin-top: 0vh;
  margin-bottom: 8vh;
  font-size: 3vw;
  font-weight: 700;
  color: white;
  font-family: "Raleway", sans-serif;
}

.advantage > img {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.advantage-img-cont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.ad-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ad-block-text {
  color: white;
  font-size: 1.5vw;
  flex: 1;
  font-family: "Raleway", sans-serif;
  text-align: center;
}
.client-head {
  text-align: center;
  margin-top: 9vh;
  margin-bottom: 8vh;
  font-size: 3vw;
  font-weight: 700;
}
.brand-image-pc {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 12vh;
}
.brand-image-mobile {
  display: none;
}
.lets-talk {
  padding-top: 14vh;
  padding-bottom: 6vh;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.lets-talk > p {
  display: inline-block;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  color: white;
  font-size: 3vw;
  /* min-height: 10vh; */
}

.lets-talk > a {
  display: inline-block;
  font-family: "Raleway", sans-serif;
  padding: 1vw;
  border-radius: 50px;
  color: #e44041;
  background-color: white;
  border: none;
  margin-top: 4vh;
  width: 9vw;
  margin-left: 5vw;
  position: relative;
  bottom: 1vh;
  font-weight: 800;
  font-size: 1vw;
  text-decoration: none;
}

.footer {
  display: flex;
  padding-top: 10vh;
}

.about-us-cont {
  flex: 1;
}
.about-head {
  color: #393939;
  font-weight: 500;
  font-size: 3vw;
  font-family: "Raleway", sans-serif;
}
.about-desc {
  font-weight: 300;
  font-size: 1.5vw;
  width: 85%;
  font-family: "Raleway", sans-serif;
}
.comp-social-cont {
  flex: 1;
}
.social-cont {
  display: block;
  width: 70%;
  margin-left: auto;
  padding-top: 5vh;
}
.social-cont > p {
  font-family: "Raleway", sans-serif;
}

.social-link-cont {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.social-link {
  border-radius: 50%;
  background-color: #e44041;
  width: 3vw;
  height: 3vw;
  align-items: center;
  display: flex;
  justify-content: center;
}

.copyright-line {
  color: #e44041;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-weight: 300;
  font-family: "Raleway", sans-serif;
}
.scrollDown {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .bike-iframe {
    bottom: 15%;
  }
  .iframe-overlay {
    display: none;
  }
  .App {
    background: none;
  }
  .wid-85 {
    width: 95%;
  }
  .break-line {
    display: none;
  }
  .navbar {
    padding-bottom: 6vh;
  }
  .nav-logo {
    width: 8vw;
    height: 8vw;
  }
  .head-data-cont {
    display: block;
  }
  .head-main-heading {
    font-size: 10vw;
    text-align: center;
  }
  .head-main-line {
    font-size: 5vw;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .bike-qr-container {
    display: none;
  }
  .ar-cont {
    height: 50vh;
    bottom: 0;
  }
  .project-head {
    font-size: 7vw;
    margin-bottom: 5vh;
  }
  .project-cont {
    grid-template-columns: 1fr;
    width: 90%;
    overflow: hidden;
  }
  .indi-pro-cont {
    margin-top: 5vh;
  }
  .indi-pro-img {
    width: 100%;
    margin-top: 0vh;
  }
  .ini-pro-data {
    width: 90%;
    bottom: 8vh;
  }
  .ini-pro-data > p {
    font-size: 5vw;
    flex: 2;
  }
  .scrollDown {
    color: #fff;
    position: absolute;
    display: flex;
    bottom: 1rem;
    gap: 1rem;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    flex-direction: column;
  }
  .form {
    display: block;
    min-height: 0;
    width: 100%;
    background-image: url("./assets/1.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
  .form-tagline-cont > p {
    width: 95%;
    margin-right: auto;
    font-size: 7vw;
    text-align: center;
  }
  .form-data-cont {
    width: 90%;
    margin-top: 10vh;
    min-height: 0;
    margin-bottom: 10vh;
  }
  .input-feild {
    padding: 4vw;
  }
  .textarea-feild {
    padding: 4vw;
  }
  .form-sub-butt {
    padding: 4vw;
  }
  .advantage {
    margin-top: -2rem;
    padding-top: 5vh;
    padding-bottom: 3vh;
    background-image: url("./assets/2.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .advantage > p {
    font-size: 7vw;
    margin-bottom: 0;
    margin-top: 10vh;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-family: "Raleway", sans-serif;
  }
  .advantage-img-cont {
    grid-template-columns: 1fr;
    margin-bottom: 10vh;
  }
  .ad-block {
    flex-direction: row;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  .ad-block-img-cont {
    flex: 1;
  }
  .ad-block-img {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .ad-block-text {
    font-size: 5vw;
    flex: 1;
    margin-left: 5vw;
  }
  .client-head {
    font-size: 7vw;
  }
  .brand-image-pc {
    display: none;
  }
  .brand-image-mobile {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 12vh;
  }
  .lets-talk {
    background-image: url("./assets/3.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
  .lets-talk > p {
    display: block;
    font-size: 7vw;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
  }
  .lets-talk > a {
    display: block;
    font-family: "Raleway", sans-serif;
    padding: 2vw;
    border-radius: 50px;
    color: #e44041;
    background-color: white;
    border: none;
    margin-top: 4vh;
    width: 70%;
    margin-left: 5vw;
    /* position: relative; */
    /* bottom: 1vh; */
    font-weight: 800;
    font-size: 4vw;
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;
  }
  .footer {
    display: block;
    padding-top: 0 !important;
  }
  .about-head {
    font-size: 7vw;
    text-align: center;
  }
  .about-desc {
    width: 95%;
    text-align: center;
    font-size: 4vw;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .social-cont {
    display: flex;

    flex-direction: column;
    align-items: center;
    width: 95%;
    margin-bottom: 5vh;
  }
  .social-cont > p {
    text-align: center;
  }
  .social-link {
    width: 12vw;
    height: 12vw;
  }
  .pc-qr {
    display: none;
  }
  .mobile-qr {
    display: block;
  }
}
